import React, { useState, useEffect } from 'react';
import ApiCall from "../../../../utility/apiCall";
import { useInput } from "../../../../utility/useInput";
import SharedBuilder from "../SharedBuilder";
import Button from '@material-ui/core/Button';
import SharedQuizMasterPlayBuilder from "../SharedQuizMasterPlayBuilder";

const SimpleBuilder = () => {
    const [apiCallString, setApiCallValues] = useState(null);
    const [questionData, setQuestionData] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { value:valueEasy, bind:bindEasy, } = useInput('');
    const { value:valueMedium, bind:bindMedium, } = useInput('');
    const { value:valueHard, bind:bindHard, } = useInput('');
    const [trueFalseValue, setTrueFalseValue] = useState(false);
    const [reveal, setReveal] = useState(false);
    const [quizMasterPlay, setQuizMasterPlay] = useState(false);

    useEffect(() => {
        setQuestions(questionData);
        setIsLoading(false);
    }, [questionData]);

    useEffect(() => {
        apiCallString && ApiCall(apiCallString, setQuestionData);
    }, [apiCallString]);

    const submitForm = e => {
        e.preventDefault();
        setQuestions(null);
        setIsLoading(true);

        const questionValues = valueEasy > 0 || valueMedium > 0 || valueHard > 0
            ? {
                easy: valueEasy,
                medium: valueMedium,
                hard: valueHard,
                trueFalse: trueFalseValue,
            }
            : {
                easy: 10,
                medium: 10,
                hard: 10,
                trueFalse: trueFalseValue,
            }
        ;

        setApiCallValues(questionValues);
    };

    const toggleReveal = () => setReveal(!reveal);

    return (
        <div className={`simpleBuilder sharedBuilder ${isLoading ? 'loading' : ''}`}>
            <h3>Simple Builder</h3>
            <p className="instruction">
                Set the number of hard / medium / easy questions below, or enter nothing to allow a random 30 questions to be picked.<br /><br />
                The questions we provide will all be in the form of "Multiple Choice", or "True or False",
                however it's up to you whether or not you want to give the options to your friends!
            </p>

            <form onSubmit={submitForm}>
                <label>
                    <span>Allow Quiz Master to play too:</span>&nbsp;
                    <input
                        type="checkbox"
                        onClick={() => setQuizMasterPlay(!quizMasterPlay)}
                        value={quizMasterPlay}
                        className="quizMasterPlay"
                    />
                </label>
                <br /><br />

                <div role="group">
                    <label>
                        <span>Number of hard questions:</span>
                        <input
                            type="number"
                            max="30"
                            placeholder="0"
                            {...bindHard}
                        />
                    </label>

                    <label>
                        <span>Number of medium questions:</span>
                        <input
                            type="number"
                            max="30"
                            placeholder="0"
                            {...bindMedium}
                        />
                    </label>

                    <label>
                        <span>Number of easy questions:</span>
                        <input
                            type="number"
                            max="30"
                            placeholder="0"
                            {...bindEasy}
                        />
                    </label>

                    <label>
                        <span>Remove True/False questions:</span>
                        <input
                            type="checkbox"
                            onClick={() => setTrueFalseValue(!trueFalseValue)}
                            value={trueFalseValue}
                        />
                    </label>
                </div>

                <Button
                    variant="contained"
                    type="submit"
                    className="success"
                >
                    Get my questions
                </Button>
            </form>

            {questions && questions.length > 0 &&
                <>
                    {questions.map((q, idx) => {
                        const {
                            id,
                            question,
                            correct_answer: answer,
                            incorrect_answers: incorrectAnswers,
                            type,
                        } = q;

                        return (
                            <div key={`${id}_${idx}`}>
                                {quizMasterPlay
                                    ? <SharedQuizMasterPlayBuilder
                                        id={id}
                                        question={question}
                                        answer={answer}
                                        incorrectAnswers={incorrectAnswers}
                                        type={type}
                                        idx={idx}
                                        reveal={reveal}
                                    />
                                    : <SharedBuilder
                                        id={id}
                                        question={question}
                                        answer={answer}
                                        incorrectAnswers={incorrectAnswers}
                                        type={type}
                                        idx={idx}
                                        reveal={reveal}
                                    />
                                }
                            </div>
                        )
                    })}

                    <Button
                        variant="contained"
                        onClick={toggleReveal}
                        className="success"
                    >
                        {reveal ? 'Hide Answers' : 'Reveal Answers'}
                    </Button>
                </>
            }
        </div>
    );
};

export default SimpleBuilder;
