import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ComplexBuilderItem from './ComplexBuilderItem';
import ComplexApiCall from '../../../../utility/complexApiCall';
import SharedBuilder from '../SharedBuilder';
import SharedQuizMasterPlayBuilder from '../SharedQuizMasterPlayBuilder';
import Button from '@material-ui/core/Button';

const ComplexBuilder = () => {
    const [questionCategories, setQuestionCategories] = useState(null);
    const [questionCategoryCount, setQuestionCategoryCount] = useState(null);
    const [numChildren, setNumChildren] = useState(1);
    const [roundState, setRoundState] = useState([
        {
            category_select: '',
            difficulty_select: '',
            numberOfQuestions: 0,
            round_name: '',
        },
    ]);
    const [roundValues, setRoundValues] = useState([]);
    const [roundToRender, setRoundToRender] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [trueFalseValue, setTrueFalseValue] = useState(false);
    const [reveal, setReveal] = useState(false);
    const [quizMasterPlay, setQuizMasterPlay] = useState(false);

    useEffect(() => {
        const getAllCategories = axios.get('https://opentdb.com/api_category.php');
        const getCategoryCount = axios.get('https://opentdb.com/api_count_global.php');

        axios
            .all([getAllCategories, getCategoryCount])
            .then(axios.spread((...responses) => {
                const allCategories = responses[0];
                const categoryCount = responses[1];

                setQuestionCategories(allCategories.data?.trivia_categories);
                setQuestionCategoryCount(categoryCount.data?.categories);

                console.log('allCategories: ', allCategories);
                console.log('categoryCount: ', categoryCount);
            })).catch(errors => {
            // react on errors.
        });
    }, []);

    useEffect(() => {
        setRoundToRender(roundToRender.concat(roundValues));
        // eslint-disable-next-line
    }, [roundValues]);

    const handleRoundChange = (e) => {
        const updatedRound = [...roundState];

        updatedRound[e.target.dataset.idx][e.target.className] =
            (e.target?.options
            && e.target?.options[e.target.selectedIndex]?.dataset?.itemdata)
            || e.target.value;

        if (e.target.dataset.updatename === "true") {
            updatedRound[e.target.dataset.idx]['round_name'] = e.target?.options && e.target?.options[e.target.selectedIndex]?.dataset?.roundname;
        }

        setRoundState(updatedRound);
        console.log('updatedRound: ', updatedRound);
    };

    // const removeRound = (e) => {
    //     const updatedRound = [...roundState];
    //
    //     console.log('roundState: ', roundState);
    //     console.log('e.target.dataset.idx: ', e.target.dataset.idx);
    //     console.log('builderItems: ', builderItems);
    //
    //     updatedRound.splice(Number(e.target.dataset.idx), 1);
    //     builderItems.splice(Number(e.target.dataset.idx), 1);
    //     setNumChildren(children => children -= 1);
    //     setRoundState(updatedRound);
    //     console.log('builderItems: ', builderItems);
    //     console.log('updatedRound: ', updatedRound);
    //
    //     // const items = builderItems.filter(item => item.id === itemId);
    //     // this.setState({ items: items });
    // };

    const toggleReveal = () => setReveal(!reveal);

    const builderItems = [];
    for (let i = 0; i < numChildren; i += 1) {
        builderItems.push(
            <ComplexBuilderItem
                key={i}
                roundNumber={i}
                roundNumberToDisplay={i + 1}
                questionCategories={questionCategories}
                handleRoundChange={handleRoundChange}
                // removeRound={removeRound}
                questionCategoryCount={questionCategoryCount}
                setTrueFalseValue={setTrueFalseValue}
                trueFalseValue={trueFalseValue}
            />
        );
    }

    const addNewRow = () => {
        const blankRound = {
            category_select: '',
            difficulty_select: '',
            numberOfQuestions: 0,
            round_name: '',
        };

        setRoundState([...roundState, {...blankRound}]);
        setNumChildren(children => children += 1);
    };

    const formSubmit = e => {
        e.preventDefault();

        setErrorMessage(null);
        setIsLoading(true);
        setRoundToRender([]);
        setRoundValues([]);
        ComplexApiCall(roundState, setRoundValues, setErrorMessage, setIsLoading);
    };

    return (
        <div className={`complexBuilder sharedBuilder ${isLoading ? 'loading' : ''}`}>
            <h3>Round Builder</h3>
            <p className="instruction">
                With the round builder you can create your own rounds of the quiz based on a chosen category - you can still set the difficulty & number of questions per round too!<br /><br />
                The questions we provide will all be in the form of "Multiple Choice", or "True or False",
                however it's up to you whether or not you want to give the options to your friends!
            </p>

            <form id="complexBuilderForm" onSubmit={formSubmit}>
                <label>
                    <span>Allow Quiz Master to play too:</span>&nbsp;
                    <input
                        type="checkbox"
                        onClick={() => setQuizMasterPlay(!quizMasterPlay)}
                        value={quizMasterPlay}
                        className="quizMasterPlay"
                    />
                </label>
                <br /><br />
                {builderItems}
            </form>

            <Button
                onClick={addNewRow}
                variant="contained"
                type="submit"
                className="complexBuilder__addRound basic"
            >
                Add a round
            </Button>

            <Button
                variant="contained"
                form="complexBuilderForm"
                type="submit"
                className="complexBuilder__submit success"
            >
                Get my questions
            </Button>

            {errorMessage && <p className="complexBuilder__error-message">{errorMessage}</p>}

            {roundToRender.map(round => {
                const {
                    // category,
                    questions,
                    roundName,
                } = round;

                return (
                    <div className="complexBuilder__questions">
                        <h3>{roundName}</h3>

                        {questions.map((roundQuestion, idx) => {
                            const {
                                question,
                                incorrect_answers: incorrectAnswers,
                                correct_answer: answer,
                                id,
                                type,
                            } = roundQuestion;

                            console.log('roundQuestion: ', roundQuestion);
                            console.log('id: ', id);

                            return (
                                <div key={`${id}_${idx}`} className="sharedBuilder__question">
                                    {quizMasterPlay ?
                                        <SharedQuizMasterPlayBuilder
                                            id={id}
                                            question={question}
                                            answer={answer}
                                            incorrectAnswers={incorrectAnswers}
                                            type={type}
                                            idx={idx}
                                            reveal={reveal}
                                        />
                                    :
                                        <SharedBuilder
                                            id={id}
                                            question={question}
                                            answer={answer}
                                            incorrectAnswers={incorrectAnswers}
                                            type={type}
                                            idx={idx}
                                            reveal={reveal}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {roundToRender.length > 0 &&
                <Button
                    variant="contained"
                    onClick={toggleReveal} className="success"
                >
                    Reveal Answers
                </Button>
            }
        </div>
    );
};

export default ComplexBuilder;
