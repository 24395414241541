import React from "react";

const SharedBuilder = ({ id, idx, type, question, answer, incorrectAnswers }) => {
    return (
        <div key={`${id}_${idx}`} className="sharedBuilder__question">
            <p className="sharedBuilder__question-number">{idx + 1}.</p>
            <div className="sharedBuilder__question-qAndA">
                <p className="sharedBuilder__question-question">
                    <span className="sharedBuilder__question-type">
                        Question Type:
                    </span>
                    <span>{type === 'multiple' ? 'Multiple Choice' : 'True or False'}</span>
                </p>
                <p className="sharedBuilder__question-question">
                    <span className="sharedBuilder__question-type">
                        Question:
                    </span>
                    <span dangerouslySetInnerHTML={{__html: question}}/>
                </p>
                <p className="sharedBuilder__question-answer">
                    <span className="sharedBuilder__question-type">
                        Answer:
                    </span>
                    <span dangerouslySetInnerHTML={{__html: answer}}/>
                </p>
                {type === 'multiple'
                    ? <p className="sharedBuilder__question-incorrectAnswers">
                        <span className="sharedBuilder__question-type">
                            Incorrect Answers:
                        </span>
                        <span dangerouslySetInnerHTML={{__html: incorrectAnswers }}/>
                    </p>
                : ''}
            </div>
        </div>
    );
};

export default SharedBuilder;
