import React from "react";
import shuffle from 'lodash.shuffle';

const SharedQuizMasterPlayBuilder = ({ id, idx, type, question, answer, incorrectAnswers, reveal }) => {
    const mixedQuestionsAnswers = shuffle([answer, ...incorrectAnswers]);
    console.log('mixed: ' , mixedQuestionsAnswers);

    return (
        <div key={`${id}_${idx}`} className="sharedBuilder__question">
            <p className="sharedBuilder__question-number">{idx + 1}.</p>
            <div className="sharedBuilder__question-qAndA">
                <p className="sharedBuilder__question-question">
                    <span className="sharedBuilder__question-type">
                        Question Type:
                    </span>
                    <span>{type === 'multiple' ? 'Multiple Choice' : 'True or False'}</span>
                </p>

                <p className="sharedBuilder__question-question">
                    <span className="sharedBuilder__question-type">
                        Question:
                    </span>
                    <span dangerouslySetInnerHTML={{__html: question}}/>
                </p>

                {type === 'multiple'
                    ? (
                        <p className="sharedBuilder__question-answer">
                            <span className="sharedBuilder__question-type">
                                Potential answers:
                            </span>
                            {mixedQuestionsAnswers.map((potentialAnswer, idx) => (
                                <>
                                    <span key={`potential_answer_${idx}`} dangerouslySetInnerHTML={{__html: potentialAnswer }} />
                                    <span className="spacer">,&nbsp;</span>
                                </>
                            ))}
                        </p>
                    ) : ''}

                <p className="sharedBuilder__question-answer">
                    <span className="sharedBuilder__question-type">
                        Answer:
                    </span>
                    {reveal
                        ? <span dangerouslySetInnerHTML={{__html: answer}}/>
                        : <span className="hidden">Scroll down to reveal answers...</span>
                    }
                </p>
            </div>
        </div>
    );
};

export default SharedQuizMasterPlayBuilder;
