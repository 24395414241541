import React from 'react';
import './App.scss';
import Banner from './components/Banner/Banner';
import Footer from "./components/Footer/Footer";
import QuizBuilder from "./components/QuizBuilder/QuizBuilder";

const App = () => (
    <div className="App">
        <Banner />
        <div className="App__textBlock">
            <p>
                You can choose between the 'Simple Builder',
                or the 'Round Builder' using the button below.<br /><br />
                The 'Round Builder' will allow you to choose categories etc.
                so I'd recommend using that if you want some added control over your questions!
            </p>
        </div>
        <QuizBuilder />
        <Footer />
    </div>
);

export default App;
