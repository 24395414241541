import React, { useState } from 'react';
import SimpleBuilder from './SubComponents/SimpleBuilder/SimpleBuilder';
import ComplexBuilder from './SubComponents/ComplexBuilder/ComplexBuilder';
import Button from '@material-ui/core/Button';

const QuizBuilder = () => {
    const [toggleBuilder, setToggleBuilder] = useState(false);

    return (
        <div className="quizBuilder">
            <Button
                onClick={() => setToggleBuilder(!toggleBuilder)}
                variant="contained"
                className="basic"
            >
                {toggleBuilder
                    ? <>Switch to Simple Builder</>
                    : <>Switch to Round Builder</>
                }
            </Button>

            {!toggleBuilder
                ? <SimpleBuilder/>
                : <ComplexBuilder/>
            }
        </div>
    );
};

export default QuizBuilder;
