import axios from 'axios';

const ApiCall = (apiCallValues, setQuestionData) => {
    const {
        easy,
        medium,
        hard,
        trueFalse,
    } = apiCallValues;
    console.log('trueFalse: ', trueFalse);
    console.log('typeof trueFalse: ', typeof trueFalse);
    const baseApiCall = `https://opentdb.com/api.php?amount=30${trueFalse ? '&type=multiple' : ''}`;
    const easyApiCall = `${baseApiCall}&difficulty=easy&amount=${easy || 0}`;
    const mediumApiCall = `${baseApiCall}&difficulty=medium&amount=${medium || 0}`;
    const hardApiCall = `${baseApiCall}&difficulty=hard&amount=${hard || 0}`;
    const requestOne = axios.get(easyApiCall);
    const requestTwo = axios.get(mediumApiCall);
    const requestThree = axios.get(hardApiCall);

    axios
        .all([requestOne, requestTwo, requestThree])
        .then(axios.spread((...responses) => {
            const responseOne = responses[0];
            const responseTwo = responses[1];
            const responseThree = responses[2];

            setQuestionData([
                ...responseOne.data.results,
                ...responseTwo.data.results,
                ...responseThree.data.results,
            ]);
        })).catch(errors => {
            // react on errors.
    });
};

export default ApiCall;
