import React from 'react';

const ComplexBuilderItem = ({
    roundNumberToDisplay,
    roundNumber,
    questionCategories,
    handleRoundChange,
    removeRound,
    questionCategoryCount,
    setTrueFalseValue,
    trueFalseValue,
}) => {
    return (
        <div className="complexBuilder__item" role="group">
            <div className="complexBuilder__item-round-number">Round {roundNumberToDisplay}.</div>

            <label>
                Please select the questions category for this round:
                <select onChange={handleRoundChange} data-idx={roundNumber} data-updatename={true} className="category_select">
                    <option>Please select a category for this round:</option>
                    {questionCategories
                    && questionCategories.length > 0
                    && questionCategories.map(category => (
                        <option key={category.id} data-itemdata={category.id} data-roundname={category.name}>
                            {category.name} ({questionCategoryCount && questionCategoryCount[category.id]?.total_num_of_questions})
                        </option>
                    ))}
                </select>
            </label>

            <label>
                Please select the questions difficulty for this round:
                <select onChange={handleRoundChange} data-idx={roundNumber} className="difficulty_select">
                    <option>Please select a difficulty:</option>
                    <option data-itemdata="easy">Easy</option>
                    <option data-itemdata="medium">Medium</option>
                    <option data-itemdata="hard">Hard</option>
                    <option data-itemdata="random">Random mixture</option>
                </select>
            </label>

            <label>
                Please enter the number of questions for this round:
                <input
                    placeholder="0"
                    type="number"
                    data-idx={roundNumber}
                    className="numberOfQuestions"
                    onChange={handleRoundChange}
                    max="30"
                />
            </label>

            <label>
                <span>Remove True/False questions:</span>
                <input
                    type="checkbox"
                    onClick={() => setTrueFalseValue(!trueFalseValue)}
                    value={trueFalseValue}
                    className="trueFalse"
                    onChange={handleRoundChange}
                    data-idx={roundNumber}
                />
            </label>

            {/*<button onClick={removeRound} data-idx={roundNumber}>Remove round</button>*/}
        </div>
    );
};

export default ComplexBuilderItem;
