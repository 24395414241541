import React from 'react';
import logo from './logo.svg';

const Banner = () => (
    <div className="banner">
        <img src={logo} alt="Build a pub quiz!" />
        <h1 className="vh">Build a pub quiz</h1>
        <h2>A simple tool to quickly create a pub quiz for you and your friends</h2>
    </div>
);

export default Banner;
