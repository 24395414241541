import axios from 'axios';

const ComplexApiCall = (
    complexApiCallValues,
    setRoundValues,
    setErrorMessage,
    setIsLoading,
    trueFalseValue,
) => {
    complexApiCallValues.forEach(values => {
        const {
            category_select: categorySelect,
            difficulty_select: difficultySelect,
            numberOfQuestions,
            round_name: roundName,
            trueFalse: trueFalseValue,
        } = values;

        console.log('values: ', values);

        axios.get('https://opentdb.com/api.php?', {
            params: {
                category: categorySelect,
                difficulty: difficultySelect === 'random' ? '' : difficultySelect,
                amount: numberOfQuestions,
                type: trueFalseValue ? 'multiple' : '',
            }
        })
            .then(function (response) {
                console.log(response);
                setRoundValues([
                    {
                        category: response.data.results[0]?.category,
                        questions: response.data.results,
                        roundName: roundName,
                    }
                ]);

                if(response.data.response_code === 1) {
                    setErrorMessage(`
                        Sorry, failed to fetch questions for round "${roundName}" with difficulty
                        setting "${difficultySelect}". 
                        There may not be enough questions for this difficulty setting.
                    `);
                }

            })
            .catch(function (error) {
                console.log('error: ', error);
            })
            .finally(function () {
                // always executed
                setIsLoading(false);
            });
    });
};

export default ComplexApiCall;
